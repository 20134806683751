<template>
    <div class="rules-layout">
        <keep-alive include="RuleListView">
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
export default {
    name: 'RulesLayout',
}
</script>

<style lang="scss" scoped>
.rules-layout {
    width: 100%;
    height: 100%;
    background-color: #f2f5f7;
    overflow-y: auto;
}
</style>
